import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes, { oneOf } from "prop-types";
import React, { useEffect, useState } from "react";

import theme from "../../theme";
import mq from "../../utils/mediaQuery";
import Hide from "../Hide";
import LogoName from "../Icon/LogoName";
import Navigation, { navigationItem } from "../Navigation";
import ReplacebleLink from "../ReplaceableLink";
import {
  HEADER_HEIGHT,
  HEADER_HEIGHT_SMALL,
  HEADER_LOGO_WIDTH_DESKTOP,
  HEADER_LOGO_WIDTH_DESKTOP_SMALL,
  HEADER_LOGO_WIDTH_MOBILE,
} from "./constants";

const LogoLink = styled(ReplacebleLink)`
  position: relative;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    transition: ${theme.transition()};

    &:hover {
      fill: ${theme.color("primary.light")};
    }

    ${({ mobile }) =>
      mobile
        ? `
        width: ${HEADER_LOGO_WIDTH_MOBILE}px;
        fill: ${theme.color("text.light")};
      `
        : `
        width: ${HEADER_LOGO_WIDTH_DESKTOP}px;
        height: ${HEADER_LOGO_WIDTH_DESKTOP}px;
        fill: ${theme.color("primary")};
      `}

    width: ${({ isSmall }) =>
      isSmall
        ? `${HEADER_LOGO_WIDTH_DESKTOP_SMALL}px`
        : `${HEADER_LOGO_WIDTH_DESKTOP}px`};
  }
`;

const HeaderNavigation = styled(Navigation)`
  ul li {
    margin: 0 20px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    ${({ mobile }) =>
      mobile &&
      `
      margin: 0;
      margin-bottom: ${theme.spacing("sm")};
      font-size: 20px;

      &::before {
        background: ${theme.color("text.light")};
      }

      > * {
        color: ${theme.color("text.light")};
      }
    `};
  }
`;

const HamburgerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
`;

const Hamburger = styled.div`
  width: 100%;
  height: 3px;
  background: ${theme.color("primary")};
`;

const CollapsableWrapper = styled(motion.div)`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 200;
  background: ${theme.color("primary")};
`;

const ScrollWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${theme.spacing("s")};
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
`;

const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme.spacing("s")};
  a {
    color: ${theme.color("text.light")};
  }
`;

const CloseButton = styled.div`
  position: relative;
  height: ${theme.space("l")};
  width: ${theme.space("l")};
  border: 2px solid ${theme.color("text.light")};
  border-radius: 69%;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 75%;
    height: 2px;
    transform-origin: center center;
    border-radius: 2px;
    background: ${theme.color("text.light")};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
    border: 2px solid ${theme.color("primary")};
  }
`;

const lightAppearanceStyles = (theme) => css`
  ${LogoWrapper} svg {
    fill: white;
  }

  ${HeaderNavigation} ul li {
    > * {
      color: ${theme.color("text.light")};
    }

    &::before {
      background: ${theme.color("text.light")};
    }

    &:hover {
      > * {
        color: ${theme.color("primary.light")};
      }

      &::before {
        background: ${theme.color("primary.light")};
      }
    }
  }

  ${Hamburger} {
    background: ${theme.color("text.light")};
  }
`;

const StickyBackground = css`
  &::before {
    transform: translateY(0);
  }
`;

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    transform: translateY(-100%);
    transition: ${theme.transition()};
  }

  ${({ isSticky }) => isSticky && StickyBackground};
`;

const StyledContentWrapper = styled.div`
  position: relative;
  box-sizing: content-box;
  padding: 0 ${theme.spacing("sm")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;

  transition: height 500ms ease-in-out;
  height: ${({ isSmall }) =>
    isSmall ? `${HEADER_HEIGHT_SMALL}px` : `${HEADER_HEIGHT}px`};

  ${({ appearance, isSticky }) =>
    appearance === "light" && !isSticky && lightAppearanceStyles(theme)};

  ${mq("3")} {
    padding: 0 ${theme.spacing("xxl")};
  }
`;

export function HeaderComponent({
  logo,
  homeUrl,
  homeAs,
  navigationItems,
  appearance,
  breakpoint,
  activeItem,
  ...props
}) {
  const [visible, setVisible] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const [isSmall, setSmall] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const target = document.body;
      const position = target.getBoundingClientRect();
      setSticky(position.top >= 0 ? false : true);
      setSmall(position.top < -150 ? true : false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  return (
    <>
      <Wrapper breakpoint={breakpoint} isSticky={isSticky} {...props}>
        <StyledContentWrapper
          isSmall={isSmall}
          appearance={appearance}
          isSticky={isSticky}
        >
          <LogoLink url={homeUrl} as={homeAs} title="Home">
            <LogoWrapper isSmall={isSmall}>
              <LogoName isSmall={isSmall}></LogoName>
            </LogoWrapper>
          </LogoLink>

          <Hide
            width={breakpoint}
            render={(p) => (
              <HeaderNavigation
                isSmall={isSmall}
                items={navigationItems}
                active={activeItem}
                {...p}
              />
            )}
          />

          <Hide
            width={breakpoint}
            down
            render={(p) => (
              <div {...p}>
                <HamburgerWrapper onClick={() => setVisible(true)}>
                  <Hamburger />
                  <Hamburger />
                  <Hamburger />
                </HamburgerWrapper>
              </div>
            )}
          />
        </StyledContentWrapper>
      </Wrapper>

      <Hide
        width={breakpoint}
        down
        render={(p) => (
          <div {...p}>
            <AnimatePresence>
              {visible && (
                <CollapsableWrapper
                  initial={{
                    y: "-100%",
                  }}
                  animate={{
                    y: 0,
                    transition: theme.get("animation.quickReveal.transition"),
                  }}
                  exit={{
                    y: "-100%",
                    transition: theme.get("animation.quickReveal.transition"),
                  }}
                >
                  <ScrollWrapper>
                    <ButtonWrapper>
                      <LogoLink
                        url={homeUrl}
                        as={homeAs}
                        onClick={() => setVisible(false)}
                      >
                        <LogoWrapper mobile>{logo}</LogoWrapper>
                      </LogoLink>
                      <CloseButton onClick={() => setVisible(false)} />
                    </ButtonWrapper>
                    <HeaderNavigation
                      mobile
                      items={navigationItems}
                      vertical
                      active={activeItem}
                      onItemClick={() => setVisible(false)}
                    />
                    <ContactData>
                      <a href="tel:+31 (0)30 2737 424 ">+31 (0)30 2737 424 </a>
                      <a href="mailto:info@greenberry.nl">info@greenberry.nl</a>
                    </ContactData>
                  </ScrollWrapper>
                </CollapsableWrapper>
              )}
            </AnimatePresence>
          </div>
        )}
      />
    </>
  );
}

HeaderComponent.propTypes = {
  logo: PropTypes.node,
  navigationItems: PropTypes.arrayOf(navigationItem),
  activeItem: navigationItem,
  appearance: oneOf(["default", "light"]),
  breakpoint: PropTypes.number,
  homeUrl: PropTypes.string,
  homeAs: PropTypes.elementType,
  headerColumns: PropTypes.number,
};

HeaderComponent.defaultProps = {
  appearance: "default",
  breakpoint: 740,
};

const Suspended = (props) => {
  const isSSR = typeof window === "undefined";
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <HeaderComponent {...props} />
        </React.Suspense>
      )}
    </>
  );
};

export default Suspended;
